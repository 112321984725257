import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import { PaintedFrame } from "../components/painted-frame"

const Prices = () => (
  <Layout pageInfo={{ pageName: "tarieven", pageTitle: "Tarieven" }}>
    <Seo title="Tarieven" />

    <section className="general-page" id="about-us">

      <Container>
        <div className="section-heading">
          <h2>Algemene informatie</h2>
        </div>
        <Row>
          <Col>
            <div className="block">
              <p>
                Bij het ‘lezen’ van een tekening komt best wat kijken. Om een tekening goed te kunnen analyseren is het
                goed om eerst wat achtergrondinformatie te hebben. Dit zijn
                <ul>

                  <li>de geboortedatum van de tekenaar;</li>
                  <li>het geslacht van de tekenaar;</li>
                  <li>wanneer de tekening is gemaakt;</li>
                  <li>of de tekenaar keuze had uit verschillende materialen (zowel om mee te tekenen als om op te
                    tekenen);
                  </li>
                  <li>de reden waarom ervoor wordt gekozen een tekening te laten analyseren;</li>
                  <li>een korte beschrijving van de persoonlijke situatie (gezinssituatie en school) van de tekenaar;
                  </li>
                  <li>eventuele bijzonderheden.</li>
                </ul>
              </p>
            </div>
          </Col>
        </Row>

        <div className="section-heading">
          <h2>Tarieven</h2>
        </div>
        <Row>
          <Col>
            <div className="block">
              <p>
                Er zijn verschillende mogelijkheden wanneer je een tekening wilt laten analyseren.
              </p>

              <Row className="justify-content-md-around">
                <Col sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                  <PaintedFrame>
                    <h2>Schriftelijke analyse</h2>
                    <div className="block">
                      <div className="margin-top">
                        <p>
                          De gehele uitgewerkte analyse kan per e&#8209;mail worden verstuurd of per post worden
                          toegezonden
                          zodat deze rustig kan worden doorgelezen.
                          <br />
                          <br />
                          Dit is mogelijk voor € 39,95.
                        </p>
                      </div>
                    </div>
                  </PaintedFrame>
                </Col>

                <Col sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                  <PaintedFrame>
                    <h2>Bespreking analyse</h2>
                    <div className="block">
                      <div className="margin-top">
                        <p>
                          Er wordt een afspraak met mij gemaakt (in mijn praktijk of via skype) om de analyse samen te
                          bespreken. Er kunnen dan vragen over de analyse worden gesteld en ik kan -&nbsp;indien
                          nodig&nbsp;- tips
                          en adviezen geven naar aanleiding van de analyse.
                          <br />
                          <br />
                          Dit is mogelijk voor € 59,95.
                        </p>
                      </div>
                    </div>
                  </PaintedFrame>
                </Col>

                <Col sm={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                  <PaintedFrame>
                    <h2>Combinatie analyse</h2>
                    <div className="block">
                      <div className="margin-top">
                        <p>
                          Er wordt een afspraak met mij gemaakt (in mijn praktijk of via skype) om de analyse samen te
                          bespreken, waarbij vragen over de analyse kunnen worden gesteld en ik -&nbsp;indien
                          nodig&nbsp;- tips
                          en
                          adviezen kan geven naar aanleiding van de analyse. Hierna zal de gehele uitgewerkte analyse
                          (inclusief een korte samenvatting van het gesprek) per e-mail worden verstuurd of per post
                          worden toegezonden zodat deze kan worden nagelezen.
                          <br />
                          <br />
                          Dit is mogelijk voor € 69,95.
                        </p>
                      </div>
                    </div>
                  </PaintedFrame>
                </Col>

              </Row>
            </div>
          </Col>
        </Row>

        <div className="section-heading">
          <h2> Overige informatie en voorwaarden</h2>
        </div>
        <Row>
          <Col>

            <div className="block">
              <p>
                Het bedrag van de analyse die u kiest dient voorafgaand aan de analyse, bij toezending van de tekening
                en de daarbij behorende informatie, te worden voldaan.
                Mijn streven is om de analyse zo snel mogelijk, in ieder geval binnen 10 dagen, uit te werken.
              </p>

              <p>
                Voor de overige voorwaarden: zie de <Link to="/algemene-voorwaarden">Algemene Voorwaarden</Link>.
              </p>
            </div>

          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default Prices
