import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export function PaintedFrame({ quote, header, children }) {
  return (
    <div style={{ display: "grid" }}>
      {/* You can use a GatsbyImage component if the image is dynamic */}
      <StaticImage
        style={{
          gridArea: "1/1",
          // You can set a maximum height for the image, if you wish.
          width: 370,
          height: 520,
        }}
        layout="fullWidth"
        // This is a presentational image, so the alt should be an empty string
        alt=""
        src={"../images/border.png"}
        formats={["auto", "webp", "avif"]}
      />
      <div className="painted-frame" style={{
        // By using the same grid area for both, they are stacked on top of each other
        gridArea: "1/1",
        position: "relative",
      }}>
        {/* Any content here will be centered in the component */}
        {children}
      </div>

    </div>
  )
}